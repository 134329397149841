/**
 * 投放列表
 */
import Vue from 'vue'
import { Page,Input,DatePicker,locale,Table,Button,Message } from 'iview';
import lang from 'iview/dist/locale/en-US';
locale(lang)
Vue.component('Page', Page);
Vue.component('Input', Input);
Vue.component('DatePicker', DatePicker);
Vue.component('Table', Table);
Vue.component('Button', Button);
import { language,terminal,content,sizeList,sizeSubList,leadingList,locationList,colorList,imageTextList,
    getMarketBannerService,
    editMarketImageTextService,
    addProductDataService,
    getMarketBannerTemplateService,
    getMarketSuperSaleTemplateService,
    getMarketDiscoverTemplateService,
    getMarketSuperSaleService,
    getMarketDiscoverService,
    getMarketBrandWeeklyService,
    getMarketFeaturedService,
    editMarketSuperSaleService,
    editDiscoverService,
    editBrandWeeklyService,
    editFeaturedService,
} from "@/service/marketing-service";
import { classification } from '@/service/spu-group-service'
import { formatUnit,dateFormat } from '@/utils/tools'
import Header from '@/components/Header/header.vue'
import { mapMutations, mapState } from "vuex";
import StyleDown from "@/components/StyleDown/styleDown.vue";
const LaunchEdit = {
    name:'LaunchList',
    components:{
        Header,
        StyleDown
    },
    computed: {
        ...mapState({
            launchId: state => state.launch.launchId,  //获取状态管理投放id
            launchTitle: state => state.launch.launchTitle,  //获取状态管理投放标题
            launchName: state => state.launch.launchName,  //获取状态管理投放名称
            typeId: state => state.launch.typeId,  //获取状态管理分类id
            selectList: state => state.product.selectList, //获取状态管理选择的商品
        }),
    },
    data() {
        return {
            loadingStatus:false,
            formatUnit:formatUnit,
            language:language,//语言
            terminal:terminal, //终端
            content:content, //内容
            sizeList:sizeList, //文字大小
            sizeSubList:sizeSubList, //副标题文字大小
            leadingList:leadingList, //间距大小
            locationList:locationList, //位置
            colorList:colorList, //颜色
            classification:classification,//分类
            editObj:{}, //编辑banner数据集
        }
    },
    created() {
        if(this.launchTitle === 'banner'){
            if(this.launchId!==''){
                this.onMarketBanner().then()
            }else{
                this.onMarketBannerTemplate().then()
            }
        }
        if(this.launchTitle === 'content'){
            if(this.launchName === 'Super Sale'){
                if(this.launchId!==''){
                    this.onMarketSuperSale().then()
                }else{
                    this.onMarketSuperSaleTemplate().then()
                }
            }
            if(this.launchName === 'Discover'){
                if(this.launchId!==''){
                    this.onMarketDiscover().then()
                }else{
                    this.onMarketDiscoverTemplate().then()
                }
            }
            if(this.launchName === 'Brand Weekly'){
                if(this.launchId!==''){
                    this.onMarketBrandWeekly().then()
                }else{
                    this.onMarketSuperSaleTemplate().then()
                }
            }
            if(this.launchName === 'Featured'){
                if(this.launchId!==''){
                    this.onMarketFeatured().then()
                }else{
                    this.onMarketDiscoverTemplate().then()
                }
            }
        }
    },
    methods: {
        ...mapMutations('product',['SET_SELECT_LIST']),
        onSelectContent() {
            if(this.launchName === 'Featured'){
                return content.slice(1,3)
            }
            if(this.launchName === 'Super Sale' || this.launchName === 'Brand Weekly'){
                return content.slice(2,3)
            }
            if(this.launchName !=='Featured' && this.launchName!=='Brand Weekly' && this.launchName !=='Super Sale'){
                return content
            }
        },
        /**
         * 添加图文结构体
         */
        onLaunchAdd(){
            this.editObj.en.contentEn.textList.push(imageTextList)
        },
        /**
         * 上传图片文件
         * @param event
         * @param n
         */
        onFileDataChange(event){
            const file =  event.target.files[0];
            if (file.size > 5 * 1024 * 1024) {
                Message.error('请选择5M以内的图片')
                return
            }
            this.onAddProductData(file).then()
        },
        /**
         * 选择商品
         */
        onSelectProduct() {
            this.$router.push('/product/select')
            this.SET_SELECT_LIST(this.editObj.en.selectionEn.products)
        },
        /**
         * 上传图片文件
         * @param event
         * @param n
         */
        onImgDataChange(event,key,index){
            const file =  event.target.files[0];
            if (file.size > 5 * 1024 * 1024) {
                Message.error('请选择5M以内的图片')
                return
            }
            this.onAddImageProductData(file,key,index).then()
        },
        /**
         * 返回至列表页
         */
        onBack() {
            this.$router.back()
        },
        /**
         * 选中分类
         */
        onSignupCheck(id){
            for(let i in classification){
                if(id === classification[i].id){
                    classification[i].checkBox? classification[i].checkBox = 0: classification[i].checkBox = 1
                }
            }
            if(this.editObj.en.cidList.indexOf(id) > -1){
                this.editObj.en.cidList.splice(this.editObj.en.cidList.indexOf(id),1)
            }else{
                this.editObj.en.cidList.push(id)
            }
            console.log(this.editObj.en.cidList)
        },
        /**
         * 选择起始月日
         * @param data
         */
        onStartMonthValue(data){
            this.editObj.en.startTime = data
        },
        /**
         * 选择结束月日
         * @param data
         */
        onEndMonthValue(data){
            this.editObj.en.endTime = data
        },
        /**
         * 选择终端
         * @param id
         */
        onTerminal(id){
            this.editObj.en.osType = id
        },
        /**
         * 选择终端
         * @param id
         */
        onContent(id){
            this.editObj.en.contentType = id
        },
        /**
         * 提交数据
         */
        onEditSubmit(){
            if(this.launchTitle === 'banner'){
                this.onEditMarketImageText().then()
            }
            if(this.launchTitle === 'content'){
                if(this.launchName === 'Super Sale'){
                    this.onEditMarketSuperSaleText().then()
                }
                if(this.launchName === 'Discover'){
                    this.onEditMarketDiscoverText().then()
                }
                if(this.launchName === 'Brand Weekly'){
                    this.onEditMarketBrandWeeklyText().then()
                }
                if(this.launchName === 'Featured'){
                    this.onEditMarketFeaturedText().then()
                }
            }
        },
        /**
         * 获取banner 数据
         * @returns {Promise<void>}
         */
        async onMarketBanner() {
            try {
                let params = {
                    id:this.launchId
                }
                const {code,data} = await getMarketBannerService(params)
                if(code === 1){
                    for(let i in data.en.cidList){
                        for(let j in classification){
                            if(data.en.cidList[i] === classification[j].id){
                                classification[j].checkBox = 1
                            }
                        }
                    }
                    this.editObj = data
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 获取 super sale 数据
         * @returns {Promise<void>}
         */
        async onMarketSuperSale() {
            try {
                let params = {
                    id:this.launchId
                }
                const {code,data} = await getMarketSuperSaleService(params)
                if(code === 1){
                    this.editObj = data
                    for(let i in data.en.cidList){
                        for(let j in classification){
                            if(data.en.cidList[i] === classification[j].id){
                                classification[j].checkBox = 1
                            }
                        }
                    }
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * get 获取 discover 数据
         * @returns {Promise<void>}
         */
        async onMarketDiscover() {
            try {
                let params = {
                    id:this.launchId
                }
                const {code,data} = await getMarketDiscoverService(params)
                if(code === 1){
                    this.editObj = data
                    for(let i in data.en.cidList){
                        for(let j in classification){
                            if(data.en.cidList[i] === classification[j].id){
                                classification[j].checkBox = 1
                            }
                        }
                    }
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 获取 brand weekly 数据
         * @returns {Promise<void>}
         */
        async onMarketBrandWeekly() {
            try {
                let params = {
                    id:this.launchId
                }
                const {code,data} = await getMarketBrandWeeklyService(params)
                if(code === 1){
                    this.editObj = data
                    for(let i in data.en.cidList){
                        for(let j in classification){
                            if(data.en.cidList[i] === classification[j].id){
                                classification[j].checkBox = 1
                            }
                        }
                    }
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 获取 Featured 数据
         * @returns {Promise<void>}
         */
        async onMarketFeatured() {
            try {
                let params = {
                    id:this.launchId
                }
                const {code,data} = await getMarketFeaturedService(params)
                if(code === 1){
                    this.editObj = data
                    for(let i in data.en.cidList){
                        for(let j in classification){
                            if(data.en.cidList[i] === classification[j].id){
                                classification[j].checkBox = 1
                            }
                        }
                    }
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 获取banner 模版数据
         * @returns {Promise<void>}
         */
        async onMarketBannerTemplate() {
            try {
                const {code,data} = await getMarketBannerTemplateService()
                if(code === 1){
                    this.editObj = data
                    this.editObj.en.startTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    this.editObj.en.endTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    this.editObj.en.osType = 1
                    this.editObj.en.status = 1

                    this.editObj.cn.startTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    this.editObj.cn.endTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    this.editObj.cn.osType = 1
                    this.editObj.cn.status = 1
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 获取super sale 模版数据
         * @returns {Promise<void>}
         */
        async onMarketSuperSaleTemplate() {
            try {
                const {code,data} = await getMarketSuperSaleTemplateService()
                if(code === 1){
                    this.editObj = data
                    this.editObj.en.startTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    this.editObj.en.endTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    this.editObj.en.osType = 1
                    this.editObj.en.status = 1

                    this.editObj.cn.startTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    this.editObj.cn.endTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    this.editObj.cn.osType = 1
                    this.editObj.cn.status = 1
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 获取 discover 模版数据
         * @returns {Promise<void>}
         */
        async onMarketDiscoverTemplate() {
            try {
                const {code,data} = await getMarketDiscoverTemplateService()
                if(code === 1){
                    this.editObj = data
                    this.editObj.en.startTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    this.editObj.en.endTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    this.editObj.en.osType = 1
                    this.editObj.en.status = 1

                    this.editObj.cn.startTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    this.editObj.cn.endTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    this.editObj.cn.osType = 1
                    this.editObj.cn.status = 1
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 编辑图文数据
         * @returns {Promise<void>}
         */
        async onEditMarketImageText() {
            try {
                this.loadingStatus = true
                let params = this.editObj
                    params.en.status = 2
                    params.cn.status = 2
                if(this.launchName === 'Top Banner'){
                    params.en.type = 1
                    params.cn.type = 1
                }
                if(this.launchName === 'Mid Banner'){
                    params.en.type = 2
                    params.cn.type = 2
                }
                if(this.launchName === 'Tail Banner'){
                    params.en.type = 3
                    params.cn.type = 3
                }
                if( params.en.contentType === 2){
                    params.en.contentEn.products = this.selectList
                }
                if( params.en.contentType === 3){
                    params.en.selectionEn.products = this.selectList
                }
                const { code } = await editMarketImageTextService(params)
                if(code === 1){
                    this.$router.push('/launch/list')
                    this.SET_SELECT_LIST([])
                }
                this.loadingStatus = false
            }catch (error){
                this.loadingStatus = false
                console.log(error)
            }
        },
        /**
         * 编辑图文数据 super sale
         * @returns {Promise<void>}
         */
        async onEditMarketSuperSaleText() {
            try {
                this.loadingStatus = true
                if(this.editObj.en.contentType === 1){
                    this.editObj.en.contentEn = ''
                    this.editObj.en.selectionEn = ''
                    this.editObj.cn.contentCn = ''
                    this.editObj.cn.selectionCn = ''
                }
                if(this.editObj.en.contentType === 2){
                    this.editObj.en.selectionEn = ''
                    this.editObj.cn.selectionCn = ''
                }
                if(this.editObj.en.contentType === 3){
                    this.editObj.en.contentEn = ''
                    this.editObj.cn.contentCn = ''
                }

                let params = this.editObj
                    params.en.type = 1
                    params.cn.type = 1
                    params.en.cid1 = this.typeId
                    params.cn.cid1 = this.typeId
                    params.en.selectionEn.products = this.selectList
                const { code } = await editMarketSuperSaleService(params)
                if(code === 1){
                    this.$router.push('/launch/list')
                    this.SET_SELECT_LIST([])
                }
                this.loadingStatus = false
            }catch (error){
                this.loadingStatus = false
                console.log(error)
            }
        },
        /**
         * 编辑图文数据 discover
         * @returns {Promise<void>}
         */
        async onEditMarketDiscoverText() {
            try {
                this.loadingStatus = true
                if(this.editObj.en.contentType === 1){
                    this.editObj.en.contentEn = ''
                    this.editObj.en.selectionEn = ''
                    this.editObj.cn.contentCn = ''
                    this.editObj.cn.selectionCn = ''
                }
                if(this.editObj.en.contentType === 2){
                    this.editObj.en.selectionEn = ''
                    this.editObj.cn.selectionCn = ''
                }
                if(this.editObj.en.contentType === 3){
                    this.editObj.en.contentEn = ''
                    this.editObj.cn.contentCn = ''
                }

                let params = this.editObj
                    params.en.type = 2
                    params.cn.type = 2
                    params.en.cid1 = this.typeId
                    params.cn.cid1 = this.typeId
                    params.en.status = 2
                    params.cn.status = 2
                    if( params.en.contentType === 2){
                        params.en.contentEn.products = this.selectList
                    }
                    if( params.en.contentType === 3){
                        params.en.selectionEn.products = this.selectList
                    }
                const { code } = await editDiscoverService(params)
                if(code === 1){
                    this.$router.push('/launch/list')
                    this.SET_SELECT_LIST([])
                }
                this.loadingStatus = false
            }catch (error){
                this.loadingStatus = false
                console.log(error)
            }
        },
        /**
         * 编辑图文数据 brand weekly
         * @returns {Promise<void>}
         */
        async onEditMarketBrandWeeklyText() {
            try {
                this.loadingStatus = true
                if(this.editObj.en.contentType === 1){
                    this.editObj.en.contentEn = ''
                    this.editObj.en.selectionEn = ''
                    this.editObj.cn.contentCn = ''
                    this.editObj.cn.selectionCn = ''
                }
                if(this.editObj.en.contentType === 2){
                    this.editObj.en.selectionEn = ''
                    this.editObj.cn.selectionCn = ''
                }
                if(this.editObj.en.contentType === 3){
                    this.editObj.en.contentEn = ''
                    this.editObj.cn.contentCn = ''
                }

                let params = this.editObj
                    params.en.type = 3
                    params.cn.type = 3
                    params.en.cid1 = this.typeId
                    params.cn.cid1 = this.typeId
                    params.en.status = 2
                    params.cn.status = 2
                  params.en.selectionEn.products = this.selectList
                const { code } = await editBrandWeeklyService(params)
                if(code === 1){
                    this.$router.push('/launch/list')
                    this.SET_SELECT_LIST([])
                }
                this.loadingStatus = false
            }catch (error){
                this.loadingStatus = false
                console.log(error)
            }
        },
        /**
         * 编辑图文数据 Featured
         * @returns {Promise<void>}
         */
        async onEditMarketFeaturedText() {
            try {
                this.loadingStatus = true
                if(this.editObj.en.contentType === 1){
                    this.editObj.en.contentEn = ''
                    this.editObj.en.selectionEn = ''
                    this.editObj.cn.contentCn = ''
                    this.editObj.cn.selectionCn = ''
                }
                if(this.editObj.en.contentType === 2){
                    this.editObj.en.selectionEn = ''
                    this.editObj.cn.selectionCn = ''
                }
                if(this.editObj.en.contentType === 3){
                    this.editObj.en.contentEn = ''
                    this.editObj.cn.contentCn = ''
                }

                let params = this.editObj
                params.en.type = 4
                params.cn.type = 4
                params.en.cid1 = this.typeId
                params.cn.cid1 = this.typeId
                params.en.status = 2
                params.cn.status = 2
                if( params.en.contentType === 2){
                    params.en.contentEn.products = this.selectList
                }
                if( params.en.contentType === 3){
                    params.en.selectionEn.products = this.selectList
                }
                const { code } = await editFeaturedService(params)
                if(code === 1){
                    this.$router.push('/launch/list')
                    this.SET_SELECT_LIST([])
                }
                this.loadingStatus = false
            }catch (error){
                this.loadingStatus = false
                console.log(error)
            }
        },

        /**
         * 上传图片文件
         */
        async onAddProductData(files) {
            try {
                let params =  new FormData()
                params.append('file',files)

                const { code,data } = await addProductDataService(params)
                if(code === 1){
                    Message.success('Success！')
                    this.editObj.en.coverImage = data.path
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 上传图片文件
         */
        async onAddImageProductData(files,key,index) {
            try {
                let params =  new FormData()
                params.append('file',files)

                const { code,data } = await addProductDataService(params)
                if(code === 1){
                    Message.success('Success！')
                    this.editObj.en.contentEn.textList[key].imageList[index].image = data.path
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
    },
}
export default LaunchEdit
