<template>
    <div class="layout-body" v-if="editObj.en">
        <!--s: Header-->
        <Header/>
        <!--e: Header-->

        <!--s: Type-->
        <div class="product-type">
            <div class="type-back"><a href="javascript:" @click="onBack">Back</a></div>
        </div>
        <!--e: Type-->

        <!--s: Launch -->
        <div class="launch-body">

            <div class="edit-top">
                <span class="name">{{ launchName }}</span>
                <span class="title"><font>ID</font>{{ editObj.en.planId }}</span>
            </div>

            <div class="edit-check edit-border-bottom">
                <div class="box">
                    <ul>
                        <li :key="key" v-for="(lang,key) in language">
                            <i class="label-icon" :class="lang.checkBox?'select':'selected'"></i>
                            <span class="label-name" :class="lang.checkBox?'title':''">{{ lang.name }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="edit-check edit-border-bottom" v-if="launchTitle === 'banner'">
                <div class="box">
                    <ul>
                        <li :key="key" v-for="(classIfi,key) in classification" @click="onSignupCheck(classIfi.id)">
                            <i class="label-icon" :class="classIfi.checkBox?'select':'selected'"></i>
                            <span class="label-name" :class="classIfi.checkBox?'title':''">{{ classIfi.name }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="edit-check edit-data">
                <ul>
                    <li>
                        <div class="title">
                            <span class="name">Start</span>
                            <i class="type-icon"></i>
                            <DatePicker :value="editObj.en.startTime" placement="bottom-start" type="date" @on-change="onStartMonthValue" format="yyyy-MM-dd"></DatePicker>
                        </div>
                        <div class="key">{{ formatUnit(editObj.en.startTime) }}</div>
                    </li>
                    <li>
                        <div class="title">
                            <span class="name">End</span>
                            <i class="type-icon"></i>
                            <DatePicker :value="editObj.en.endTime" placement="bottom-start" type="date" @on-change="onEndMonthValue" format="yyyy-MM-dd"></DatePicker>
                        </div>
                        <div class="key">{{ formatUnit(editObj.en.endTime) }}</div>
                    </li>
                    <li v-if="launchName !== 'Super Sale' && launchName !== 'Brand Weekly'">
                        <div class="title">
                            <span class="name store">Store / Brand</span>
                        </div>
                        <div class="input-box">
                            <input type="text" placeholder="Text" class="input" v-model="editObj.en.source"/>
                        </div>
                    </li>
                </ul>
            </div>

            <!--s: cover-->
            <div class="launch-box" v-if="launchName !== 'Super Sale' && launchName !== 'Brand Weekly'">
                <h1 class="headline">Cover</h1>
                <div class="launch-terminal">
                    <a href="javascript:" :class="editObj.en.osType === item.id?'active':''" :key="key" v-for="(item,key) in terminal" @click="onTerminal(item.id)">{{ item.name }}</a>
                </div>

                <div class="launch-plug">
                    <StyleDown v-model="editObj.en.kwSize" disabled  title="Size" :width="70" :margin-right="25" :data="sizeSubList" />
                    <StyleDown v-model="editObj.en.kwLeading" disabled  title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                    <StyleDown v-model="editObj.en.kwLocation" disabled  title="Location" :width="70" :margin-right="25" :data="locationList"/>
                    <StyleDown v-model="editObj.en.kwColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                </div>
                <div class="launch-text">
                    <div class="box" style="margin-right: 30px">
                        <div class="title">
                            <span style="float:left;">Key Words</span>
                            <span style="float:right;">Montserrat - Regular</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.en.keywords" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                    <div class="box" >
                        <div class="title">
                            <span style="float:left;">关键词</span>
                            <span style="float:right;">方正兰亭 - 细黑 / Montserrat - Regular</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.cn.keywords" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                </div>
                <div class="launch-plug">
                    <StyleDown v-model="editObj.en.ctSize" disabled title="Size" :width="70" :margin-right="25" :data="sizeList" />
                    <StyleDown v-model="editObj.en.ctLeading" disabled title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                    <StyleDown v-model="editObj.en.ctLocation" disabled title="Location" :width="70" :margin-right="25" :data="locationList"/>
                    <StyleDown v-model="editObj.en.ctColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                    <div class="launch-upload">
                        <span class="title">Cover image - 1270 x 480</span>
                        <div class="upload">
                            <span class="value">{{ editObj.en.coverImage }}</span>
                            <Button>Upload<input type="file" class="input" @change="onFileDataChange"/></Button>
                        </div>
                    </div>
                </div>
                <div class="launch-text">
                    <div class="box" style="margin-right: 30px">
                        <div class="title">
                            <span style="float:left;">Cover title</span>
                            <span style="float:right;">Montserrat - ExtraBold</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.en.coverTitle" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                    <div class="box" >
                        <div class="title">
                            <span style="float:left;">封面标题</span>
                            <span style="float:right;">方正兰亭 - 特黑 / Montserrat - ExtraBold</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.cn.coverTitle" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                </div>
                <div class="launch-plug">
                    <StyleDown v-model="editObj.en.csSize" disabled title="Size" :width="70" :margin-right="25" :data="sizeSubList"/>
                    <StyleDown v-model="editObj.en.csLeading" disabled title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                    <StyleDown v-model="editObj.en.csLocation" disabled title="Location" :width="70" :margin-right="25" :data="locationList"/>
                    <StyleDown v-model="editObj.en.csColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                </div>
                <div class="launch-text">
                    <div class="box" style="margin-right: 30px">
                        <div class="title">
                            <span style="float:left;">Cover subtitle</span>
                            <span style="float:right;">Montserrat - SemiBold</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.en.coverSubtitle" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                    <div class="box" >
                        <div class="title">
                            <span style="float:left;">副标题</span>
                            <span style="float:right;">方正兰亭 - 粗黑 / Montserrat - SemiBold</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.cn.coverSubtitle" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="launch-box" v-if="launchName === 'Super Sale'">
                <h1 class="headline">Cover</h1>
                <div class="launch-terminal">
                    <a href="javascript:" :class="editObj.en.osType === item.id?'active':''" :key="key" v-for="(item,key) in terminal" @click="onTerminal(item.id)">{{ item.name }}</a>
                </div>
                <div class="launch-plug">
                    <StyleDown v-model="editObj.en.mSize" disabled  title="Size" :width="70" :margin-right="25" :data="sizeSubList" />
                    <StyleDown v-model="editObj.en.mLeading" disabled  title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                    <StyleDown v-model="editObj.en.mLocation" disabled  title="Location" :width="70" :margin-right="25" :data="locationList"/>
                    <StyleDown v-model="editObj.en.mColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                </div>
                <div class="launch-text image-text">
                    <div class="box">
                        <div class="title">
                            <span class="name store">Number</span>
                            <span style="float:right;">Montserrat - ExtraBold</span>
                        </div>
                        <div class="input-box">
                            <input type="text" v-model="editObj.en.number" placeholder="x" class="input" />
                        </div>
                    </div>
                    <div class="box">
                        <div class="title">
                            <span class="name store">“More”</span>
                            <span style="float:right;">Montserrat - ExtraBold</span>
                        </div>
                        <div class="input-box">
                            <input type="text" v-model="editObj.en.more" placeholder="More" class="input" />
                        </div>
                    </div>
                    <div class="box">
                        <div class="title">
                            <span class="name store">“更多单品”</span>
                            <span style="float:right;">方正兰亭 - 特黑</span>
                        </div>
                        <div class="input-box">
                            <input type="text" v-model="editObj.cn.more" placeholder="更多单品" class="input" />
                        </div>
                    </div>
                </div>
                <div class="launch-plug" style="margin-top: 0">
                    <StyleDown v-model="editObj.en.eSize" disabled  title="Size" :width="70" :margin-right="25" :data="sizeSubList" />
                    <StyleDown v-model="editObj.en.eLeading" disabled  title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                    <StyleDown v-model="editObj.en.eLocation" disabled  title="Location" :width="70" :margin-right="25" :data="locationList"/>
                    <StyleDown v-model="editObj.en.eColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                </div>
                <div class="launch-text">
                    <div class="box" style="margin-right: 30px">
                        <div class="title">
                            <span style="float:left;">Beginning</span>
                            <span style="float:right;">Montserrat - Regular</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.en.beginning" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                    <div class="box" >
                        <div class="title">
                            <span style="float:left;">开头</span>
                            <span style="float:right;">方正兰亭 - 细黑 / Montserrat - Regular</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.cn.beginning" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                </div>
                <div class="launch-text">
                    <div class="box" style="margin-right: 30px">
                        <div class="title">
                            <span style="float:left;">Ending</span>
                            <span style="float:right;">Montserrat - Regular</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.en.ending" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                    <div class="box" >
                        <div class="title">
                            <span style="float:left;">结尾</span>
                            <span style="float:right;">方正兰亭 - 细黑 / Montserrat - Regular</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.cn.ending" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                </div>

            </div>
            <div class="launch-box" v-if="launchName === 'Brand Weekly'">
                <h1 class="headline">Cover</h1>
                <div class="launch-terminal">
                    <a href="javascript:" :class="editObj.en.osType === item.id?'active':''" :key="key" v-for="(item,key) in terminal" @click="onTerminal(item.id)">{{ item.name }}</a>
                </div>
                <div class="launch-plug">
                    <StyleDown v-model="editObj.en.bnSize" disabled  title="Size" :width="70" :margin-right="25" :data="sizeSubList" />
                    <StyleDown v-model="editObj.en.bnLeading" disabled  title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                    <StyleDown v-model="editObj.en.bnLocation" disabled  title="Location" :width="70" :margin-right="25" :data="locationList"/>
                    <StyleDown v-model="editObj.en.bnColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                    <div class="launch-upload">
                        <span class="title">Cover image - 1270 x 480</span>
                        <div class="upload">
                            <span class="value">{{ editObj.en.coverImage }}</span>
                            <Button>Upload<input type="file" class="input" @change="onFileDataChange"/></Button>
                        </div>
                    </div>
                </div>
                <div class="launch-text">
                    <div class="box" style="margin-right: 30px">
                        <div class="title">
                            <span style="float:left;">Brand name</span>
                            <span style="float:right;">Montserrat - ExtraBold</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.en.brandName" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                    <div class="box" >
                        <div class="title">
                            <span style="float:left;">品牌名称</span>
                            <span style="float:right;">方正兰亭 - 特黑 / Montserrat - ExtraBold</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.cn.brandName" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                </div>
                <div class="launch-plug" style="margin-top: 0">
                    <StyleDown v-model="editObj.en.mSize" disabled  title="Size" :width="70" :margin-right="25" :data="sizeSubList" />
                    <StyleDown v-model="editObj.en.mLeading" disabled  title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                    <StyleDown v-model="editObj.en.mLocation" disabled  title="Location" :width="70" :margin-right="25" :data="locationList"/>
                    <StyleDown v-model="editObj.en.mColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                </div>
                <div class="launch-text image-text">
                    <div class="box">
                        <div class="title">
                            <span class="name store">“More”</span>
                            <span style="float:right;">Montserrat - ExtraBold</span>
                        </div>
                        <div class="input-box">
                            <input type="text" v-model="editObj.en.more" placeholder="x" class="input" />
                        </div>
                    </div>
                    <div class="box">
                        <div class="title">
                            <span class="name store">“更多单品”</span>
                            <span style="float:right;">方正兰亭 - 特黑</span>
                        </div>
                        <div class="input-box">
                            <input type="text" v-model="editObj.cn.more" placeholder="More" class="input" />
                        </div>
                    </div>
                </div>
                <div class="launch-plug" style="margin-top: 0">
                    <StyleDown v-model="editObj.en.eSize" disabled  title="Size" :width="70" :margin-right="25" :data="sizeSubList" />
                    <StyleDown v-model="editObj.en.eLeading" disabled  title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                    <StyleDown v-model="editObj.en.eLocation" disabled  title="Location" :width="70" :margin-right="25" :data="locationList"/>
                    <StyleDown v-model="editObj.en.eColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                </div>
                <div class="launch-text">
                    <div class="box" style="margin-right: 30px">
                        <div class="title">
                            <span style="float:left;">Ending</span>
                            <span style="float:right;">Montserrat - Regular</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.en.ending" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                    <div class="box" >
                        <div class="title">
                            <span style="float:left;">结尾</span>
                            <span style="float:right;">方正兰亭 - 细黑 / Montserrat - Regular</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.cn.ending" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                </div>
            </div>
            <!--e: cover-->

            <!--s: Content-->
            <div class="launch-box launch-margin-top">
                <h1 class="headline">Content</h1>
                <div class="launch-terminal">
                    <a href="javascript:" :class="editObj.en.contentType === item.id?'select':''" :key="key" v-for="(item,key) in onSelectContent()" @click="onContent(item.id)">{{ item.name }}</a>
                </div>
                <!--s: Link-->
                <div class="launch-link" v-if="editObj.en.contentType === 1">
                    <div class="box">
                        <span class="title">Link</span>
                        <div class="input-box">
                            <input v-model="editObj.en.link" type="text" class="input" placeholder="URL"/>
                        </div>
                    </div>
                </div>
                <!--e: Link-->

                <!--s: Text-->
                <div class="launch-text-body" v-if="editObj.en.contentType === 2">
                    <h1 class="headline">Title</h1>
                    <div class="launch-plug">
                        <StyleDown v-model="editObj.en.contentEn.tSize" disabled title="Size" :width="70" :margin-right="25" :data="sizeList"/>
                        <StyleDown v-model="editObj.en.contentEn.tLeading" disabled title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                        <StyleDown v-model="editObj.en.contentEn.tLocation" disabled title="Location" :width="70" :margin-right="25" :data="locationList"/>
                        <StyleDown v-model="editObj.en.contentEn.tColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                    </div>
                    <div class="launch-text">
                        <div class="box" style="margin-right: 30px">
                            <div class="title">
                                <span style="float:left;">Title</span>
                                <span style="float:right;"> Montserrat - Light</span>
                            </div>
                            <div class="text">
                                <Input v-model="editObj.en.contentEn.name" type="textarea" :rows="2"></Input>
                            </div>
                        </div>
                        <div class="box" >
                            <div class="title">
                                <span style="float:left;">标题</span>
                                <span style="float:right;">方正兰亭 - 细黑 / Montserrat - Light</span>
                            </div>
                            <div class="text">
                                <Input v-model="editObj.cn.contentCn.name" type="textarea" :rows="2"></Input>
                            </div>
                        </div>
                    </div>
                    <div class="launch-plug">
                        <StyleDown v-model="editObj.en.contentEn.cSize" disabled title="Size" :width="70" :margin-right="25" :data="sizeSubList"/>
                        <StyleDown v-model="editObj.en.contentEn.cLeading" disabled title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                        <StyleDown v-model="editObj.en.contentEn.cLocation" disabled title="Location" :width="70" :margin-right="25" :data="locationList"/>
                        <StyleDown v-model="editObj.en.contentEn.cColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                    </div>
                    <div class="launch-text">
                        <div class="box" style="margin-right: 30px">
                            <div class="title">
                                <span style="float:left;">Info</span>
                                <span style="float:right;"> Montserrat - Regular</span>
                            </div>
                            <div class="text four">
                                <Input v-model="editObj.en.contentEn.content" type="textarea" :rows="4"></Input>
                            </div>
                        </div>
                        <div class="box">
                            <div class="title">
                                <span style="float:left;">文章信息</span>
                                <span style="float:right;">方正兰亭 - 细黑 / Montserrat - Regular</span>
                            </div>
                            <div class="text four">
                                <Input v-model="editObj.cn.contentCn.content" type="textarea" :rows="4"></Input>
                            </div>
                        </div>
                    </div>
                    <h1 class="headline launch-margin-top">Text & Images</h1>
                    <div class="launch-text-list" :key="'textList'+key" v-for="(textList,key) in editObj.en.contentEn.textList">
                        <div class="launch-plug" style="margin-top: 10px">
                            <span class="plug-title">Text</span>
                            <StyleDown v-model="editObj.en.contentEn.textList[key].cSize" disabled title="Size" :width="70" :margin-right="25" :data="sizeSubList"/>
                            <StyleDown v-model="editObj.en.contentEn.textList[key].cLeading" disabled title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                            <StyleDown v-model="editObj.en.contentEn.textList[key].cLocation" disabled title="Location" :width="70" :margin-right="25" :data="locationList"/>
                            <StyleDown v-model="editObj.en.contentEn.textList[key].cColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                        </div>
                        <div class="launch-plug" style="margin-top: 0px">
                            <span class="plug-title">Brand</span>
                            <StyleDown v-model="editObj.en.contentEn.textList[key].bSize" disabled title="Size" :width="70" :margin-right="25" :data="sizeSubList"/>
                            <StyleDown v-model="editObj.en.contentEn.textList[key].bLeading" disabled title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                            <StyleDown v-model="editObj.en.contentEn.textList[key].bLocation" disabled title="Location" :width="70" :margin-right="25" :data="locationList"/>
                            <StyleDown v-model="editObj.en.contentEn.textList[key].bColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                        </div>
                        <div class="launch-plug margin-bottom" style="margin-top: 0px">
                            <span class="plug-title">Description</span>
                            <StyleDown v-model="editObj.en.contentEn.textList[key].dSize" disabled title="Size" :width="70" :margin-right="25" :data="sizeSubList"/>
                            <StyleDown v-model="editObj.en.contentEn.textList[key].dLeading" disabled title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                            <StyleDown v-model="editObj.en.contentEn.textList[key].dLocation" disabled title="Location" :width="70" :margin-right="25" :data="locationList"/>
                            <StyleDown v-model="editObj.en.contentEn.textList[key].dColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                        </div>
                        <div class="launch-text">
                            <div class="box" style="margin-right: 30px">
                                <div class="title">
                                    <span style="float:left;">Text</span>
                                    <span style="float:right;">Montserrat - Regular</span>
                                </div>
                                <div class="text ten">
                                    <Input v-model="editObj.en.contentEn.textList[key].content" type="textarea" :rows="10"></Input>
                                </div>
                            </div>
                            <div class="box">
                                <div class="title">
                                    <span style="float:left;">文本</span>
                                    <span style="float:right;">方正兰亭 - 细黑 / Montserrat - Regular</span>
                                </div>
                                <div class="text ten">
                                    <Input v-model="editObj.en.contentEn.textList[key].cnContent" type="textarea" :rows="10"></Input>
                                </div>
                            </div>
                        </div>
                        <div class="launch-image-list" :key="'image'+index" v-for="(image,index) in textList.imageList">
                            <div class="launch-upload margin-bottom">
                                <span class="title">Image {{ index+1 }}</span>
                                <div class="upload">
                                    <span class="value">{{ textList.imageList[index].image }}</span>
                                    <Button>Upload<input type="file" class="input" @change="onImgDataChange($event,key,index)"/></Button>
                                </div>
                            </div>
                        </div>
                        <div class="launch-image-list" :key="'imageList'+index" v-for="(image,index) in textList.imageList">
                            <div class="launch-text image-text">
                                <div class="box">
                                    <div class="title">
                                        <span class="name store">Brand</span>
                                        <span style="float:right;">Montserrat Bold</span>
                                    </div>
                                    <div class="input-box">
                                        <input type="text" placeholder="Text" class="input" v-model="textList.imageList[index].brand"/>
                                    </div>
                                </div>
                                <div class="box">
                                    <div class="title">
                                        <span style="float:left;">Description</span>
                                        <span style="float:right;">Montserrat Regular</span>
                                    </div>
                                    <div class="text">
                                        <Input v-model="textList.imageList[index].description" type="textarea" :rows="2"></Input>
                                    </div>
                                </div>
                                <div class="box">
                                    <div class="title">
                                        <span style="float:left;">图片说明</span>
                                        <span style="float:right;">方正兰亭 - 细黑 / M.R.</span>
                                    </div>
                                    <div class="text">
                                        <Input v-model="textList.imageList[index].cnDescription" type="textarea" :rows="2"></Input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="launch-image-info">One image - 1270 x 720 / Two images - 620
                      50
                      Select / Edit Product List
                      50
                      x 465 / Three images - 405 x 405</div>
                    <div class="launch-select-btn">
                        <a href="javascript:" class="select" @click="onSelectProduct">Select / Edit Product List</a>
                    </div>
                    <a href="javascript:" class="launch-image-add" @click="onLaunchAdd">+</a>
                </div>
                <!--e: Text-->

                <!--s: Selection-->
                <div class="launch-selection" v-if="editObj.en.contentType === 3">
                    <div class="launch-plug" style="margin-top: 0">
                        <StyleDown v-model="editObj.en.selectionEn.tSize" disabled title="Size" :width="70" :margin-right="25" :data="sizeList"/>
                        <StyleDown v-model="editObj.en.selectionEn.tLeading" disabled title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                        <StyleDown v-model="editObj.en.selectionEn.tLocation" disabled title="Location" :width="70" :margin-right="25" :data="locationList"/>
                        <StyleDown v-model="editObj.en.selectionEn.tColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                    </div>
                    <div class="launch-text">
                        <div class="box" style="margin-right: 30px">
                            <div class="title">
                                <span style="float:left;">Title</span>
                                <span style="float:right;">Montserra - SemiBold</span>
                            </div>
                            <div class="text">
                                <Input v-model="editObj.en.selectionEn.name" type="textarea" :rows="2"></Input>
                            </div>
                        </div>
                        <div class="box" >
                            <div class="title">
                                <span style="float:left;">标题</span>
                                <span style="float:right;">方正兰亭 - 粗黑 / Montserrat - SemiBold</span>
                            </div>
                            <div class="text">
                                <Input v-model="editObj.cn.selectionCn.name" type="textarea" :rows="2"></Input>
                            </div>
                        </div>
                    </div>
                    <div class="launch-select-btn" style="margin-top: 0px">
                        <a href="javascript:" class="select" @click="onSelectProduct">Select / Edit Product List</a>
                    </div>
                </div>
                <!--e: Selection-->

            </div>
            <!--e: Content-->

            <!--s: Btn-->
            <div class="launch-btn-edit">
                <div class="box">
                    <Button>Pause</Button>
                    <Button style="margin-left: 15px">Ready</Button>
                    <Button style="margin-left: 30px" :loading="loadingStatus" @click="onEditSubmit">Save</Button>
                </div>
            </div>
            <!--s: Btn-->

        </div>
        <!--e: Launch -->

    </div>
</template>
<script>
import LaunchEdit from './edit'
export default LaunchEdit
</script>
<style lang="less">
@import "edit";
</style>
